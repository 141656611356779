import { graphql } from "gatsby"
import React from "react"

import Layout from "../../../components/layout"

const BlogPage = ({ pathContext: { locale }, data }) => (
    <Layout locale={locale}>
        <div className={"container blog"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1>{data.markdownRemark.frontmatter.title}</h1>
                </div>
            <small>{data.markdownRemark.frontmatter.date}</small>
            <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
            </div>
        </div>
    </Layout>
)

export default BlogPage

export const pageQuery = graphql`
  query BlogQuery3 {
    markdownRemark(fileAbsolutePath: { regex: "/19-01-2020-implicit-explicit.md/" }) {
      html
      frontmatter {
        author
        date
        title
      }
    }
  }`